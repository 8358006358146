<template>
  <div
    :class="{ active: isActive }"
    class="topbar"
    :style="'margin-top: ' + topbarPosition + 'px;'"
  >
    <div class="top d-flex justify-content-between align-items-center">
      <a @click="toggleMenu" class="toggle-menu-btn" ref="sidebarBtn">
        <i class="fas fa-bars"></i>
      </a>
      <div>
        <slot name="right-section"></slot>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
let glob;

export default {
  components: {},
  mixins: [],
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      topbarPosition: 0,
      lastOffset: 0
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    glob = this;

    this.lastOffset = window.pageYOffset;
    this.initEvents();
  },
  methods: {
    initEvents() {
      document.querySelector("html").addEventListener("click", (e) => {
        const clicked = e.path.slice(0, -5);
        let isMenuClosing = true;

        for (var x of clicked) {
          if (
            x.classList.contains("fd-sidebar") ||
            x.classList.contains("toggle-menu-btn")
          ) {
            isMenuClosing = false;
            break;
          }
        }

        if (window.innerWidth <= 768) {
          if (isMenuClosing) {
            this.closeMenu();
          }
        }
      });

      if (window.innerWidth <= 768) {
        window.addEventListener("scroll", this.handleScroll);
      } else {
        window.removeEventListener("scroll", this.handleScroll);
      }

      window.onresize = (e) => {
        if (window.innerWidth <= 768) {
          window.addEventListener("scroll", this.handleScroll);
        } else {
          window.removeEventListener("scroll", this.handleScroll);
          glob.topbarPosition = 0;
          glob.lastOffset = 0;
        }
      };
    },
    // ============================ HELPER METHODS =============================
    toggleMenu() {
      this.$store.commit("toggleSidebar");
    },
    closeMenu() {
      this.$store.commit("closeSidebar");
    },

    handleScroll(e) {
      let lastOffset = glob.lastOffset;
      let currentOffset = window.pageYOffset;
      let difference = currentOffset - lastOffset;

      if (currentOffset > 0) {
        if (glob.topbarPosition - difference >= 0) {
          glob.topbarPosition = 0;
        } else if (glob.topbarPosition - difference <= -67) {
          glob.topbarPosition = -67;
        } else {
          glob.topbarPosition = glob.topbarPosition - difference;
        }

        glob.lastOffset = currentOffset;
      } else if (currentOffset <= 0) {
        glob.topbarPosition = 0;

        glob.lastOffset = 0;
      }
    }
  }
};
</script>

<style lang="scss">
.topbar {
  @include flex(column, flex-start, flex-start);
  transition: all 0.5s, margin-top 0s;
  border-bottom: 1px solid #eee;
  width: calc(100% - 46px);
  position: fixed;
  height: auto;
  right: 0;
  top: 0;
  z-index: 100;

  .top {
    background-color: $color-10;
    padding: 0 15px;
    height: 66px;
    width: 100%;
    .back-btn {
      color: $color-main;
      padding: 0 15px;
      i {
        transition: 0.3s;
        cursor: pointer;
      }
    }
    .toggle-menu-btn {
      @include flex(row, center, center);
      cursor: pointer;
      height: 100%;
      i {
        @include flex(row, center, center);
        padding: 0 15px;
        height: 100%;
        color: $color-main;
      }
    }
  }
  .breadcrumb {
    width: 100%;
    position: relative;
    background-color: white;
    margin: -1px 0 0;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba($color-13, 0.1);
    }
    ul {
      display: flex;
      position: relative;
      font-weight: 300;
      padding: 16px 0;
      color: #999;
      z-index: 1;
      margin: 0;
      li {
        list-style: none;
        white-space: nowrap;
        color: $color-secondary;
        text-transform: capitalize;
        &.current-page {
          &:before {
            display: inline-block;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            color: $color-main;
            content: "/";
          }
        }
      }
    }
  }

  &.active {
    width: calc(100% - 251px);
  }
}

@media #{$breakpoint-down-sm} {
  .topbar {
    &.active {
      left: 251px;
      .back-btn {
        i {
          opacity: 0;
          visibility: hidden;
        }
      }
      .logo {
        opacity: 0;
        visibility: hidden;
        position: absolute;
      }
    }
  }
  .topbar {
    left: 0;
    width: 100%;
    .breadcrumb {
      display: none;
    }
  }
}
</style>
